<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        
        <span>Код для размещения на сайт:</span>
        <pre>
          <code>&lt;script src="https://trends-ds.com/tracker/tracker_{{tokenCurrent}}.js" defer&gt;&lt;/script&gt;</code>
        </pre>
      </div>
      <div class="col-md-12">
      </div>
    </div>
  </div>
  <div class="blocks-page row">
    <div class="cards-column col-md-6">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h5 class="card-title">ГЕНЕРАЦИЯ</h5>
            <div class="card card-pointer">
              <div class="card-body">
                <div class="col-md-12">
                  <button 
                    :disabled="isLoading || isSuccess" 
                    class="btn btn-primary mb-6 buttonGenerate" 
                    :class="{
                      'btn-primary mb-6 buttonGenerate': true,
                      'btn-success btn mb-6 buttonGenerate': isSuccess,
                      'btn-primary btn mb-6 buttonGenerate': isLoading
                    }"
                    @click="saveAndGenerate">
                    <span v-if="!isLoading && !isSuccess">Сохранить и сгенерировать новый скрипт трекера</span>
                    <span v-if="isLoading" class="loader"></span>
                    <span v-if="isSuccess" class="success">Сохранено</span>
                  </button>
                </div>
          

                <div class="col-md-12">
                  <input id='savePoint' type="checkbox" class="form-check-input" v-model="selectedEvent">
                  <label class="form-check-label" for="savePoint">Создать дату изменения</label>
                </div>
              </div>
            </div>
          </div>  
        </div>
        <div class="row">
          <div class="col-md-12">
            <h5 class="card-title">КОПИРОВАНИЕ</h5>
            <div class="card card-pointer">
              <div class="card-body">
                <div class="col-md-12">
                  <button 
                    :disabled="isLoadingCopy || isSuccessCopy" 
                    class="btn btn-primary mb-6 buttonGenerate" 
                    :class="{
                      'btn-primary mb-6 buttonGenerate': true,
                      'btn-success btn mb-6 buttonGenerate': isSuccessCopy,
                      'btn-primary btn mb-6 buttonGenerate': isLoadingCopy
                    }"
                    @click="copyTracker">
                    <span v-if="!isLoadingCopy && !isSuccessCopy">Скопировать структуру блоков в новый токен</span>
                    <span v-if="isLoadingCopy" class="loader"></span>
                    <span v-if="isSuccessCopy" class="success">Готово</span>
                  </button>
                </div>
                <div class="col-md-12">
                  <input id='copyTargetName' type="text" class="" v-model="copyTargetName">
                  <label class="" for="copyTargetName">Имя для нового токена</label>
                </div>
                <div class="col-md-12">
                  <input id='selectedCopyTarget' type="checkbox" class="form-check-input" v-model="selectedCopyTarget">
                  <label class="form-check-label" for="selectedCopyTarget">Скопировать с без указания на конкретные блоки</label>
                </div>
              </div>
            </div>
            
          </div>
          
         
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h5 class="card-title">Даты изменения</h5>
            <div class="card card-pointer">
              <div class="card-body">
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
</template>


<script>
import { ref } from 'vue';
import axios from "axios";
// Получение токена из localStorage
const token = localStorage.getItem('token');
// Добавление токена в заголовок Authorization для всех запросов
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;


export default {
  name: 'SaverTrackerPage',
  props: {
    tokenCurrent: {
      type: String,
    },
    projectCurrent: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const hostApi = ref(process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : 'http://localhost:5001');
    const selectedEvent = ref(true); 
    const selectedCopyTarget = ref(false); 
    const copyTargetName = ref('');
    const isSuccess = ref(false);
    const isLoading = ref(false);
    const isSuccessCopy = ref(false);
    const isLoadingCopy = ref(false);
    const saveAndGenerate = (id) => {
        isLoading.value = true;
        isSuccess.value = false;

        axios
        .post(hostApi.value + '/api/v1/block/saveAndGenerate', {
          savePoint: selectedEvent.value,
          clientId: localStorage.getItem('clientId'),
          projectId:  props.projectCurrent,
          tokenId: props.tokenCurrent,
        })
        .then(function(response) {
            //console.log(response.data)
            isLoading.value = false;
            isSuccess.value = true;
            setTimeout(() => {
              isSuccess.value = false;
            }, 3000); // Убираем галочку через 3 секунды
        })
        .catch(function(error) {
            console.log(error);
        });
    }
    const copyTracker = (id) => {
      //console.log(copyTargetName.value, copyTargetName);
        if (copyTargetName.value === '') {
          alert('Имя для нового токена не может быть пустым');
          return;
        }
        isLoadingCopy.value = true;
        isSuccessCopy.value = false;

        axios
        .post(hostApi.value + '/api/v1/block/copyTracker', {
          copyTarget: selectedCopyTarget.value,
          clientId: localStorage.getItem('clientId'),
          projectId:  props.projectCurrent,
          tokenId: props.tokenCurrent,
          name: copyTargetName.value,
        })
        .then(function(response) {
            console.log('copyTracker', response.data.data)
            emit('update:tokens');
            isLoadingCopy.value = false;
            isSuccessCopy.value = true;
            setTimeout(() => {
              isSuccessCopy.value = false;
            }, 3000); // Убираем галочку через 3 секунды
        })
        .catch(function(error) {
            console.log(error);
        });
    }
    return { 
      saveAndGenerate,
      selectedEvent,
      isLoading,
      isSuccess,
      isSuccessCopy,
      isLoadingCopy,
      selectedCopyTarget,
      copyTracker,
      copyTargetName
    };
  }
};
</script>

<style>
  .buttonGenerate {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    width: 100%; /* фиксированная ширина */
    height: 100%; /* фиксированная высота */
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .buttonGenerate:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  .buttonGenerate span {
    display: inline-block;
    vertical-align: middle;
  }
  .buttonGenerate .loader {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #000;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  /*
  .buttonGenerate .success {
    color: green;
    font-size: 24px;
  }
*/
  .blocks-page {
    display: flex;
  }
  .block-edit-card {
  margin-top: 7% !important;
  margin-bottom: 1% !important;;
  }

  .cards-column {
  width: 30%;
  border-right: 1px solid #ddd;
  }
  .card-pointer {
    cursor: pointer;
  }
  .card {
    text-align: left;
  }
  .card-details {
  padding: 10px;
  width: 70%;
  position: fixed;
  right: 0;
  top: 0;
  height: 90%;
  overflow-y: auto;
  background: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  }
  .card-details > div {
  margin-bottom: 10px;
  }
  .btn-close {
  position: absolute;
  right: 10px;
  top: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  }
</style>
