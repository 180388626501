<template>
  <Header />
  <div id="app" class="container">
    <div class="row">
      <div>
        <label for="project">Project</label>
        <input class="setList" list="projectList" @change="updateProjectsInput" v-model="projectCurrentName" :data-value="projectCurrent" type="text" id="project" placeholder="Проект" />
        <datalist id="projectList">
          <option v-for="item in projects" 
            :key="item.id" 
            :data-value="item.id"
            :value="item.name">
          </option>
        </datalist>
      </div>
      <div v-if="projectCurrentName">
        <label for="token">Token</label>
        <input class="setList" list="tokenList"  @change="updateTokensInput" v-model="tokenCurrentName" :data-value="tokenCurrent" type="text" id="token" placeholder="Сайт или страница" />
        <datalist id="tokenList">
          <option v-for="token in tokens" 
            :key="token" 
            :data-value="token.token" 
          >{{token.name}} | {{token.site}}</option>
        </datalist>
      </div>
    </div>
    <div v-if="tokenCurrentName">
      <nav class="nav nav-tabs mb-3">
        <button class="nav-link" :class="{ active: activeTab === 'blocks' }" @click="activeTab = 'blocks'">Блоки</button>
        <button class="nav-link" :class="{ active: activeTab === 'events' }" @click="activeTab = 'events'">События</button>
        <button class="nav-link" :class="{ active: activeTab === 'saver' }" @click="activeTab = 'saver'">Сохранение и генерация</button>
      </nav>
      <div v-if="activeTab === 'blocks'">
        <BlocksPage :cards="cards" :tokenCurrent="tokenCurrent" :projectCurrent="projectCurrent" @update:cards="updateCards" @reorder="reorderCards" @save="saveCard" />
      </div>
      <div v-if="activeTab === 'events'">
        <EventsPage :events="events" :tokenCurrent="tokenCurrent" :projectCurrent="projectCurrent" @save="saveEvent" @update:events="updateEvents" @add="addEvent" />
      </div>
      <div v-if="activeTab === 'saver'">
        <SaverTrackerPage @update:tokens="updateTokens" :events="events" :tokenCurrent="tokenCurrent" :projectCurrent="projectCurrent" @save="saveEvent" @update:events="updateEvents" @add="addEvent" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import BlocksPage from './BlocksPage.vue';
import EventsPage from './EventsPage.vue';
import SaverTrackerPage from './SaverTrackerPage.vue';
import Header from '../../components/Header.vue';
import axios from "axios";
// Получение токена из localStorage
const token = localStorage.getItem('token');
// Добавление токена в заголовок Authorization для всех запросов
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

export default {
  name: 'App',
  components: { BlocksPage, EventsPage, SaverTrackerPage, Header },

  mounted() {
    this.getProjects();
  },

  setup() {
    const activeTab = ref('blocks');
    const cards = ref([]);
    const events = ref([]);
    const tokens = ref([]);
    const projects = ref([]);
    const projectCurrentName = ref('');
    const tokenCurrentName = ref('');
    const projectCurrent = ref('');
    const tokenCurrent = ref('');
    const selectedTokens = ref([]);
    const hostApi =  process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : 'http://localhost:5001'



    const updateProjectsInput = (e) => {
      const selected= e.target.value;
      const selectedOption = projects.value.find(item => item.name === selected);
      if (selectedOption) {
        const inputValue = selectedOption.id;
        projectCurrent.value = inputValue;
                //add to localStorage
        localStorage.setItem('projectCurrent', inputValue);
        localStorage.setItem('projectCurrentName', selectedOption.name);
        //e.target.dataset.value = inputValue;
        getTokens();
      }
    }
    const updateTokensInput = (e) => {//{{token.name}} | {{token.site}}
      const selectedToken = e.target.value;
      const selectedOption = tokens.value.find(token => token.name+' | '+token.site === selectedToken);
      if (selectedOption) {
        const inputValue = selectedOption.token;
        tokenCurrent.value = inputValue;

        getCards()
        getEvents()
      }

    }
    const getCards = () => {

      axios
        .post(hostApi + '/api/v1/block/getCards', 
          {
            clientId: localStorage.getItem('clientId'),
            projectId: projectCurrent.value,
            tokenId: tokenCurrent.value,
          }
        )
        .then(function(response) {
              console.log('getCards', response.data)
              //vm.$refs.cards.value = response.data;
              updateCards(response.data.data)
              
        })
        .catch(function(error) {
              console.log(error);
        });
    }
    const getEvents = () => {

      axios
      .post(hostApi + '/api/v1/block/getEvents', 
        {
          clientId: localStorage.getItem('clientId'),
          projectId: projectCurrent.value,
          tokenId: tokenCurrent.value,
        }
      )
      .then(function(response) {
            console.log('getEvents', response.data)
            //vm.$refs.cards.value = response.data;
            updateEvents(response.data.data)
            
      })
      .catch(function(error) {
            console.log(error);
      });
    }
    const getProjects = () => {
      console.log("getProject");
      axios
        .post(hostApi + '/api/v1/project/get', 
          {
            clientId: localStorage.getItem('clientId'),
          }
        )
        .then(function(response) {
              projects.value = response.data.data;
              console.log('getProject', response.data.data)
              console.log('vm.projects', projects.value)
              if (localStorage.getItem('projectCurrent')!= null){
                const tmpProject = localStorage.getItem('projectCurrent');
                const tmpOpt = projects.value.find(item => item.id === tmpProject);
                if (tmpOpt) {
                  projectCurrent.value = localStorage.getItem('projectCurrent');
                  projectCurrentName.value = localStorage.getItem('projectCurrentName');
                  getTokens();
                }
              }
        })
        .catch(function(error) {
              console.log(error);
        });
    }
    const updateTokens = () =>{
      tokenCurrentName.value = '';
      tokenCurrent.value = '';
      getTokens();
    }
    const getTokens = () =>{
      console.log("getTokens")
      axios
      .post(hostApi + '/api/v1/token/get', 
        {
            clientId: localStorage.getItem('clientId'),
            projectId: projectCurrent.value,
        })
     .then(function(response) {
          tokens.value = response.data;
          console.log(response.data)
        })
     .catch(function(error) {
          console.log(error);
      });
    }





    const updateCards = (newCards) => {
      console.log('updateCards', newCards)
      cards.value = newCards;
    };

    const reorderCards = (event) => {
      // Handle reorder logic here, if necessary
    };

    const saveCard = (card) => {
      const cardIndex = cards.value.findIndex(c => c.id === card.id);
      if (cardIndex !== -1) {
        cards.value[cardIndex] = card;
        console.log('saveCard', card);
      } else {
        cards.value.push(card);
        console.log('addCard', card);
      }
    };

    const saveEvent = (event) => {
      // Логика для сохранения события на сервер
      console.log('saveEvent', event);

    };

    const updateEvents = (updatedEvents) => {

      events.value = updatedEvents;
      // Логика для удаления события с сервера

    };

    const addEvent = () => {
      events.value.push({
        id: Date.now(),
        class: 'EventListener',
        name: '',
        type: '',
        eventAction: '',
        eventName: '',
        target: 'elementClass',
        targetName: '',
        directionTo: ''
      });
    };

    return { activeTab, cards, events, projectCurrent, tokenCurrent, tokens, projectCurrentName, tokenCurrentName, selectedTokens, updateCards, reorderCards, saveCard, saveEvent, updateEvents, addEvent, updateProjectsInput, updateTokensInput, getCards, getProjects, getTokens, updateTokens };
  }
};
</script>
<style>
.setList {
  width: 30%;
}
</style>